import React, { useState } from "react";
import {
  formatDate,
  dateFormWithMonthName,
  authUser,
  getHashFromId,
  leadStatusType,
} from "Utils";
import { TableStyles } from "Assets/Styles";
import { CheckCircleOutlinedIcon, Delete, EditIcon } from "Assets/Icons";
import { useRecoilState, toasterGlobal, confirmGlobal } from "Atom";
import { useDeleteProjectLeadsService } from "Services/ProjectLeadsServices";
import { useHistory } from "react-router";
import StopIcon from "@mui/icons-material/Stop";
import Tooltip from "@mui/material/Tooltip";

import {
  Email,
  Loader,
  NoData,
  Pagination,
  DesktopTableDataPopup,
  ActionMenu,
  DesktopTableDataPopupCollapsable,
  ContactAction,
} from "Common";
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  useMediaQuery,
  useTheme,
  TableContent,
} from "Assets/Material";
import { StopOutlined } from "Assets/Icons";
import ProjectLeadsSwapper from "Modules/ProjectLeads/component/ProjectLeadsSwapper";
import ConversationList from "Modules/Conversation/ConversationList";
import AdminConversationRow from "Modules/Conversation/components/AdminConversationRow";
import AdminChatHeader from "Modules/Conversation/components/AdminChatHeader";
import AdminChatInfo from "Modules/Conversation/components/AdminChatInfo";
import Badge from "@mui/material/Badge";
import { SHOW_DEV_CHANGES } from "Utils/ApiConstants";

const DataList = (props) => {
  const { mutate } = useDeleteProjectLeadsService();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  const history = useHistory();

  const setToaster = useRecoilState(toasterGlobal)[1];
  const setConfirm = useRecoilState(confirmGlobal)[1];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEmail, seiIsEmail] = useState(false);
  const [swapperData, setSwapperData] = useState(null);
  const [isTablePopup, setIsTablePopup] = useState(false);
  const [isTablePopupIndustry, setIsTablePopupIndustry] = useState(false);
  const [
    isTablePopupFunctionalCapabilities,
    setIsTablePopupFunctionalCapabilities,
  ] = useState(false);

  const [conversationRequirementId, setConversationRequirementId] =
    useState(null);

  const openIndustryTablePopup = (projectLead) => {
    setSelectedRow(projectLead);
    setIsTablePopup(true);
    setIsTablePopupIndustry(true);
    setIsTablePopupFunctionalCapabilities(false);
  };

  const openFunctionalCapabilitiesTablePopup = (projectLead) => {
    setSelectedRow(projectLead);
    setIsTablePopup(true);
    setIsTablePopupIndustry(false);
    setIsTablePopupFunctionalCapabilities(true);
  };

  const closeTablePopup = () => {
    setSelectedRow(null);
    setIsTablePopup(false);
    setIsTablePopupIndustry(false);
    setIsTablePopupFunctionalCapabilities(false);
  };

  const openSwapper = (data) => {
    props?.onSwapperOpen();
    setSwapperData(data);
  };

  const closeSwapper = () => {
    props?.onSwapperClose();
    setSwapperData(null);
  };

  const deleteProjectLeads = async (id) => {
    mutate(id, {
      onSuccess: async (data, variables, context) => {
        onCloseDeleteProjectLead(true, data.message);
      },
      onError: (error, variables, context) => {
        throw new Error(error);
      },
    });
  };

  const onCloseDeleteProjectLead = (isSuccess, message) => {
    // props?.refresh();
    // setSelectedRow(null);
    // props?.onSwapperClose();
    setConfirm({ isOpen: false, delete: () => {} });
    setToaster({
      isOpen: true,
      status: isSuccess ? "success" : "fail",
      message: message,
    });
  };

  const closeEmail = () => {
    setSelectedRow(null);
    seiIsEmail(false);
  };

  const actionIcons = [
    <EditIcon
      sx={TableStyles.actionMenuItem}
      onClick={() => {
        props?.getDataEdit(selectedRow.id);
        // props?.isProjectLeadEdit();
        setAnchorEl(null);
      }}
    />,
    <Delete
      sx={TableStyles.actionMenuItem}
      onClick={() => {
        setConfirm({
          isOpen: true,
          delete: () => deleteProjectLeads(selectedRow.id),
        });
        setAnchorEl(null);
      }}
    />,
  ];

  const onClickMoreVertIcon = async (e, projectLead) => {
    setAnchorEl(e.currentTarget);
    setSelectedRow(projectLead);
  };

  const swapperEdit = (swapperData) => {
    props?.getDataEdit(swapperData);
    props?.isProjectLeadEdit();
    setAnchorEl(null);
  };

  const swapperDelete = (id) => {
    setConfirm({
      isOpen: true,
      delete: () => deleteProjectLeads(id),
    });
    setAnchorEl(null);
  };

  const onEmailOpen = (projectLead) => {
    setSelectedRow(projectLead);
    seiIsEmail(true);
  };

  return (
    <>
      {props?.isLoading && <Loader />}

      <Box sx={TableStyles.tableWrapper}>
        <TableContainer component={Paper} sx={TableStyles.tableContainer}>
          <Table sx={TableStyles.table}>
            <TableHead>
              <TableRow>
                <TableCell sx={TableStyles.tableCell}> Name </TableCell>
                <TableCell sx={TableStyles.tableCell}> Organization </TableCell>

                {!xs && !sm && (
                  <TableCell sx={TableStyles.tableCell}> Industries </TableCell>
                )}
                {!xs && !sm && (
                  <TableCell sx={TableStyles.tableCell}>
                    {" "}
                    Capabilities{" "}
                  </TableCell>
                )}
                {/* {!xs && !sm && <TableCell sx={TableStyles.tableCell}> Lead Status </TableCell>} */}
                {!xs && !sm && (
                  <TableCell
                    sx={{
                      ...TableStyles.tableCell,
                      ...{ width: ["0", "0", "15%", "12%", "10%"] },
                    }}
                  >
                    Date
                  </TableCell>
                )}
                <TableCell
                  sx={
                    !xs && !sm
                      ? {
                          ...TableStyles.tableCell,
                          ...{ width: ["0", "0", "10%", "10%", "10%"] },
                        }
                      : TableStyles.tableCell
                  }
                >
                  {" "}
                  Created By{" "}
                </TableCell>
                {!xs && !sm && (
                  <TableCell
                    align="center"
                    sx={{
                      ...TableStyles.tableCell,
                      ...{ width: "12%", textAlign: "center!important" },
                    }}
                  >
                    {" "}
                    Status{" "}
                  </TableCell>
                )}
                {SHOW_DEV_CHANGES && !xs && !sm && (
                  <TableCell
                    sx={{ ...TableStyles.tableCell, ...{ width: "10%" } }}
                  >
                    {" "}
                    Applicants{" "}
                  </TableCell>
                )}
                {!xs && !sm && (
                  <TableCell
                    sx={{ ...TableStyles.tableCell, ...{ width: "7%" } }}
                  >
                    <ActionMenu actionIcons={actionIcons} />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {props?.data?.length > 0 ? (
              <TableBody>
                {props?.data.map((projectLead, key) => {
                  
                  //   let project_lead_status = projectLead.lead_status == 0
                  //   ? "Ongoing Project"
                  //   : projectLead.lead_status == 1
                  //       ? `Open for Application ${projectLead.client_review_status === "Approved" ? "(Approved)" : `${authUser().user_type_id === 3 ? `(${{ "Pending": 'Approval Pending', 'Read': 'Client has visited', 'Sent': 'Pending', 'Approved': 'Approved'}[projectLead.client_review_status]})` : "(Approval Pending)"}`}`
                  //   : projectLead.lead_status == 2
                  //   ? "Project Discontinued"
                  //   : projectLead.lead_status == 3 ?
                  // "Demand Generation"
                  // : projectLead.lead_status == 4 ? "Under client evaluation"
                  // : projectLead.lead_status == 5 ? "Project Completed"
                  // : "";
                  const lead_status = leadStatusType.find(
                    (el) => el.value === projectLead.lead_status
                  );

                  return (
                    <TableRow
                      key={key}
                      sx={TableStyles.tableRow}
                      style={{
                        border:
                          projectLead.lead_status == null
                            ? "2px solid #21BA45"
                            : "",
                        borderLeft:
                          projectLead.lead_status == null
                            ? "5px solid #21BA45"
                            : "",
                      }}
                    >
                      <TableCell
                        // sx={(xs || sm) && TableStyles.mobilelink}
                        sx={
                          xs || sm
                            ? {
                                ...TableStyles.tabledata,
                                ...TableStyles.mobilelink,
                              }
                            : { ...TableStyles.tabledata, ...TableStyles.link }
                        }
                        // onClick={() => (xs || sm) && openSwapper(projectLead)}
                        onClick={() => {
                          const newTab = window.open('', '_blank');
                          newTab.location.href = `/project-leads-details/${getHashFromId(projectLead?.id)}`
                        }}
                      >
                        <TableContent
                          style={{
                            display: "flex",
                            gap: "0.25rem",
                            alignItems: "center",
                          }}
                          title={projectLead?.name}
                        >
                          {" "}
                          {projectLead?.name}{" "}
                          {projectLead.client_review_status === "Approved" ? (
                            <CheckCircleOutlinedIcon />
                          ) : (
                            ""
                          )}{" "}
                        </TableContent>
                      </TableCell>

                      <TableCell>
                        <TableContent title={projectLead?.organization}>
                          {projectLead?.organization}
                        </TableContent>
                      </TableCell>

                      {!xs && !sm && (
                        <TableCell sx={TableStyles.tabledata}>
                          <TableContent>
                            <DesktopTableDataPopupCollapsable
                              data={projectLead?.industries}
                              openFunc={() =>
                                openIndustryTablePopup(projectLead)
                              }
                            />
                            <DesktopTableDataPopup
                              data={projectLead?.industries}
                              onOpen={isTablePopup && isTablePopupIndustry}
                              onClose={closeTablePopup}
                              selectedRow={selectedRow?.id}
                              currentRow={projectLead.id}
                            />
                          </TableContent>
                        </TableCell>
                      )}

                      {!xs && !sm && (
                        <TableCell sx={TableStyles.tabledata}>
                          <TableContent>
                            <DesktopTableDataPopupCollapsable
                              data={projectLead?.fc_data}
                              openFunc={() =>
                                openFunctionalCapabilitiesTablePopup(
                                  projectLead
                                )
                              }
                            />
                            <DesktopTableDataPopup
                              data={projectLead?.fc_data}
                              onOpen={
                                isTablePopup &&
                                isTablePopupFunctionalCapabilities
                              }
                              onClose={closeTablePopup}
                              selectedRow={selectedRow?.id}
                              currentRow={projectLead.id}
                            />
                          </TableContent>
                        </TableCell>
                      )}
                      {/* {!xs && !sm && (
                        <TableCell sx={TableStyles.tabledata}>
                          <TableContent title={project_lead_status}>
                            {project_lead_status}
                          </TableContent>
                        </TableCell>
                      )} */}
                      {!xs && !sm && (
                        <TableCell>
                          <TableContent>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "20px 1fr",
                              }}
                            >
                              {/* <div
                                title={
                                  projectLead?.lead_status == 0
                                    ? "Engaged"
                                    : projectLead?.lead_status == 1
                                    ? "In Progress"
                                    : projectLead?.lead_status == 2
                                    ? "Discontinued"
                                    : ""
                                }
                              >
                                {projectLead?.lead_status !== null && (
                                  <StopIcon
                                    // title={
                                    //   projectLead?.lead_status == 0
                                    //     ? "#21BA45"
                                    //     : projectLead?.lead_status == 1
                                    //     ? "#607EBB"
                                    //     : projectLead?.lead_status == 2
                                    //     ? "#F25C5C"
                                    //     : "transparent"
                                    // }
                                    style={{
                                      color:
                                        projectLead?.lead_status == 0
                                          ? "#21BA45"
                                          : projectLead?.lead_status == 1
                                          ? "#607EBB"
                                          : projectLead?.lead_status == 2
                                          ? "#F25C5C"
                                          : "transparent",
                                      backgroundColor:
                                        projectLead?.lead_status == 0
                                          ? "#21BA45"
                                          : projectLead?.lead_status == 1
                                          ? "#607EBB"
                                          : projectLead?.lead_status == 2
                                          ? "#F25C5C"
                                          : "transparent",
                                      fontSize: "0.7rem",
                                    }}
                                  />
                                )}
                              </div> */}
                              {/* &nbsp; */}
                              <div>
                                {projectLead?.createdAt &&
                                  dateFormWithMonthName(projectLead?.createdAt)}
                              </div>
                            </div>

                            {/* // formatDate(projectLead.estimated_start_date)} */}
                          </TableContent>
                        </TableCell>
                      )}
                      <TableCell>
                        <TableContent>
                          {projectLead.created_by_user_name}
                        </TableContent>
                      </TableCell>

                      {!xs && !sm && (
                        <TableCell align="left">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: lead_status?.color || "white",
                                width: "10px",
                                height: "10px",
                              }}
                            ></div>
                            <p>{lead_status?.name}</p>
                          </div>
                        </TableCell>
                      )}
                      {SHOW_DEV_CHANGES && (
                        <TableCell>
                          <TableContent>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "1rem",
                              }}
                            >
                              {projectLead.total_applications}
                              {projectLead.total_applications_with_applied_status >
                                0 && (
                                <span style={TableStyles.appliedBadge}>
                                  {
                                    projectLead.total_applications_with_applied_status
                                  }
                                </span>
                              )}
                            </div>
                          </TableContent>
                        </TableCell>
                      )}
                      {!xs && !sm && (
                        <TableCell align="center">
                          <ActionMenu
                            onClickMoreVertIcon={(e) =>
                              onClickMoreVertIcon(e, projectLead)
                            }
                            anchorEl={anchorEl}
                            onOpen={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            actionIcons={actionIcons}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={xs || sm ? 3 : 7}
                    align="center"
                    sx={TableStyles.tabledata}
                  >
                    <NoData />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>

          <Pagination
            perPage={props?.pagination?.perPage}
            from={props?.pagination.from}
            to={props?.pagination.to}
            totalDataItem={props?.pagination.totalDataItem}
            previous={props?.pagination.previous}
            next={props?.pagination.next}
          />
        </TableContainer>

        <Email
          data={selectedRow}
          id={selectedRow?.id}
          title={selectedRow && selectedRow.name}
          onOpen={isEmail}
          onClose={closeEmail}
          requestFrom="ProjectLead"
        />

        <ProjectLeadsSwapper
          isOpen={props?.isSwapper}
          swapperData={swapperData}
          onClose={() => closeSwapper()}
          delete={() => swapperDelete(swapperData.id)}
          edit={() => swapperEdit(swapperData)}
          onEmailOpen={(data) => onEmailOpen(data)}
        />

        <ConversationList
          visible={!!conversationRequirementId}
          onHide={() => setConversationRequirementId(null)}
          entity_id={conversationRequirementId}
          entity_type="REQUIREMENT"
          viewComponents={{
            listView: AdminConversationRow,
            header: AdminChatHeader,
            info: AdminChatInfo,
          }}
        />
      </Box>
    </>
  );
};

export default DataList;
