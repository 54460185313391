import AdminBilling from "Modules/AdminBilling";
import ResetProfilePassword from "Modules/Auth/ResetProfilePassword";
import MarvinLeads from "Modules/ProjectLeads/MarvinLeads";
import MarvinLeadsDetails from "Modules/ProjectLeads/component/MarvinLeadsDetails";
import { lazy } from "react";

const Register = lazy(() => import("Modules/Auth/Register"));
const Login = lazy(() => import("Modules/Auth/Login"));
const AdminLogin = lazy(() => import("Modules/Auth/AdminLogin"));
const ResetPassword = lazy(() => import("Modules/Auth/ResetPassword"));
const Projects = lazy(() => import("Modules/Projects/Projects"));
const CreateProject = lazy(() => import("Modules/Projects/Component/CreateProject"));
const ProjectDetails = lazy(() => import("Modules/Projects/Component/ProjectDetails"));
const TalentManagement = lazy(() => import("Modules/TalentManagement/TalentManagement"));
const TalentManagementIndexed = lazy(() => import("Modules/TalentManagement/TalentManagementIndexed"));
const ConsultantProfile = lazy(() => import("Modules/ConsultantProfile/ConsultantProfile"));
const Clients = lazy(() => import("Modules/Clients/Clients"));
const ClientProfile = lazy(() => import("Modules/ClientProfile/ClientProfile"));
const ClientUserProfile = lazy(() => import("Modules/ClientUserProfile/ClientUserProfile"));
const ProjectLeads = lazy(() => import("Modules/ProjectLeads/ProjectLeads"));
const ProjectLeadsDetails = lazy(() => import("Modules/ProjectLeads/component/ProjectLeadsDetails"));
const CaseStudies = lazy(() => import("Modules/CaseStudies/CaseStudies"));
const PublishCaseStudies = lazy(() => import("Modules/CaseStudies/PublishCaseStudies"));
const ApprovalPanel = lazy(() => import("Modules/ApprovalPanel/ApprovalPanel"));
const SuggestedConsultants = lazy(() => import("Modules/SuggestedConsultants/SuggestedConsultants"));
const SuggestedConsultantProfile = lazy(() =>
  import("Modules/SuggestedConsultants/Component/SuggestedConsultantProfile")
);
const SuggestClient = lazy(() => import("Modules/TalentManagement/Component/SuggestClient/SuggestClient"));

const ConsultantBillingDashboard = lazy(() => import("Modules/ConsultantBilling/ConsultantBillingDashboard"));

const Relay = lazy(() => import("Modules/Auth/Relay"))
const LeadsClientRelay = lazy(() => import("Modules/ProjectLeads/component/LeadClientRelay"));

const SovrenAdmin = lazy(() => import("Modules/ProjectLeads/SovrenAdmin"));
const AdminProfile = lazy(() => import("Modules/AdminProfile/AdminProfile"));
const PageNotFound = lazy(() => import("Modules/PageNotFound/PageNotFound"));

const routes = [
  {
    title: "Unauthorised",
    component: Login,
    path: "/",
    type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Authorised",
    component: Projects,
    path: "/",
    type: "authorised",
    access_level: [1, 2, 3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Projects",
    component: Projects,
    path: "/projects",
    type: "authorised",
    access_level: [1, 2, 3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Create Project",
    component: CreateProject,
    path: "/create-project",
    type: "authorised",
    access_level: [3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Project Details",
    component: ProjectDetails,
    path: "/project-details/:id",
    type: "authorised",
    access_level: [1, 2, 3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  // {
  //   title: "Talent",
  //   component: TalentManagement,
  //   path: "/talent-management",
  //   type: "authorised",
  //   access_level: [3],
  //   inHeader: true,
  //   isProfile: false,
  //   profile_access_level: null,
  // },
  {
    title: "Talent",
    component: TalentManagementIndexed,
    path: "/talent-management-indexed",
    type: "authorised",
    access_level: [3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Share Consultants",
    component: SuggestClient,
    path: "/talent-management/share-consultant/:id",
    type: "authorised",
    access_level: [3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
 
  {
    title: "Engaged Consultants",
    component: TalentManagement,
    path: "/engaged-consultants",
    type: "authorised",
    access_level: [1],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Consultant Profile",
    component: ConsultantProfile,
    path: "/consultant-details/:id",
    type: "authorised",
    access_level: [1, 3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Consultant Details Edit",
    component: ConsultantProfile,
    path: "/consultant-details-edit/:id",
    type: "authorised",
    access_level: [3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Consultant Profile",
    component: ConsultantProfile,
    path: "/consultant-profile",
    type: "authorised",
    access_level: [2],
    inHeader: false,
    isProfile: true,
    profile_access_level: 2,
  },
  {
    title: "Clients",
    component: Clients,
    path: "/clients",
    type: "authorised",
    access_level: [2, 3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Client Details",
    component: ClientProfile,
    path: "/client-details/:id",
    type: "authorised",
    access_level: [2, 3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Client Profile",
    component: ClientProfile,
    path: "/client-profile",
    type: "authorised",
    access_level: [1],
    inHeader: false,
    isProfile: true,
    profile_access_level: 1,
  },
  {
    title: "Leads",
    component: ProjectLeads,
    path: "/project-leads",
    type: "authorised",
    access_level: [3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Unapproved Leads",
    component: MarvinLeads,
    path: "/unapproved-leads",
    type: "authorised",
    access_level: [3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
   },
   {
    title: "Unapproved Leads Details",
    component: MarvinLeadsDetails,
    path: "/unapproved-leads-details/:id",
    type: "authorised",
    access_level: [3, 1.1],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
   },
  {
    title: "Leads",
    component: ProjectLeadsDetails,
    path: "/project-leads-details/:id",
    type: "authorised",
    access_level: [3, 1.1],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Leads",
    component: LeadsClientRelay,
    path: "/lead-login",
    // type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Suggested Consultants",
    component: SuggestedConsultants,
    path: "/suggested-consultants",
    type: "authorised",
    access_level: [1],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Suggested Consultant Profile",
    component: SuggestedConsultantProfile,
    path: "/suggested-consultant-profile/:id",
    type: "authorised",
    access_level: [1],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Knowledge ",
    component: CaseStudies,
    path: "/knowledge-management",
    type: "authorised",
    access_level: [1, 2, 3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Knowledge ",
    component: PublishCaseStudies,
    path: "/publish-case-study",
    type: "authorised",
    access_level: [1, 2, 3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Approval ",
    component: ApprovalPanel,
    path: "/approval-panel",
    type: "authorised",
    access_level: [3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Approval Panel Project Details",
    component: ProjectDetails,
    path: "/approval-panel/:id",
    type: "authorised",
    access_level: [1, 2, 3],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Admin Profile",
    component: AdminProfile,
    path: "/admin-profile",
    type: "authorised",
    access_level: [3],
    inHeader: false,
    isProfile: true,
    profile_access_level: 3,
  },
  {
    title: "Invoices",
    component: AdminBilling,
    path: "/admin-invoices",
    type: "authorised",
    access_level: [3],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Billing",
    component: ConsultantBillingDashboard,
    path: "/consultant-billing",
    type: "authorised",
    access_level: [2],
    inHeader: true,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Register",
    component: Register,
    path: "/admin-register",
    type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Login",
    component: Login,
    path: "/login",
    type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Admin Login",
    component: AdminLogin,
    path: "/admin-login",
    type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Reset Password",
    component: ResetPassword,
    path: "/reset-password",
    type: "unauthorised",
    access_level: [],
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Reset Profile Password",
    component: ResetProfilePassword,
    path: "/reset-profile-password",
    // type: "authorised",
    access_level: [],
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Relay",
    component: Relay,
    path: "/relay",
    // type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Sovren Admin Panel",
    component: SovrenAdmin,
    path: "/sovren-admin",
    // type: "unauthorised",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
  {
    title: "Page Not Found",
    component: PageNotFound,
    path: "*",
    type: "default",
    access_level: [],
    inHeader: false,
    isProfile: false,
    profile_access_level: null,
  },
];

export default routes;
